<template>
  <div class="rs-add-photos__wrap" :class="{ '--open': showGallery }">
    <div class="rs-add-photos__btn" @click="toggleGallery">
      <i class="light-icon-camera-plus"></i>
      <span v-if="uploadedPhotos.length === 0"> Attach Photos </span>
      <span v-else> Attached Photos ({{ uploadedPhotos.length }}) </span>
      <i class="light-icon-chevron-down" v-if="showGallery"></i>
      <i class="light-icon-chevron-up" v-else></i>
    </div>
    <div class="rs-add-photos__btn-row"></div>
    <div class="rs-gallery__wrap">
      <div class="rs-gallery__add-new-btn" @click="takePicture" v-ripple>
        <i class="light-icon-camera-plus"></i>
        Add New Photo
      </div>
      <div class="rs-gallery__scroll-wrap">
        <div class="rs-gallery__images-wrap">
          <div class="rs-gallery__image-wrap" v-for="photo in uploadedPhotos" :key="photo.path">
            <div class="rs-gallery__image-overlay" v-if="!photo.uploaded">
              <span v-if="!photo.uploadProgress">Preparing...</span>
              <span v-else-if="photo.uploadProgress < 100">Uploading... {{ photo.uploadProgress }}%</span>
              <span v-else>Saving...</span>
            </div>
            <div class="rs-gallery__image-remove" @click="removePhoto(photo)">
              <i class="light-icon-trash"></i>
            </div>
            <img :src="photo.url" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import { Plugins, CameraResultType, CameraSource, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
import { getSlug } from '../utils/slug';
export default {
  props: ['responseId', 'responseMeta', 'photos'],
  data() {
    return {
      uploadValue: null,
      photosLocal: [],
      showGallery: false,
      uploadedPhotos: [],
    };
  },
  watch: {
    photos: {
      handler: function(newValue) {
        this.uploadedPhotos = newValue;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toggleGallery() {
      this.showGallery = !this.showGallery;
    },
    async takePicture() {
      const { Camera, Filesystem } = Plugins;

      const image = await Camera.getPhoto({
        quality: 60,
        // allowEditing: true,
        source: CameraSource.Camera,
        saveToGallery: true,
        // resultType: CameraResultType.webPath,
        resultType: CameraResultType.DataUrl,
      });
      // var imageUrl = image.webPath;
      let imageUrl = image.dataUrl;
      // Can be set to the src of an image now
      // imageElement.src = imageUrl;
      const fileName = new Date().getTime() + '.jpeg';
      let fileCloudPath = `rurban/district/${this.responseId}_${fileName}`;
      if (this.responseMeta && this.responseMeta.district) {
        fileCloudPath = `rurban/${getSlug(this.responseMeta.district)}/${getSlug(this.responseMeta.cluster)}_${getSlug(this.responseMeta.panchayat)}_${getSlug(this.responseMeta.habitation)}_${fileName}`;
      }
      // const savedFileImage = {
      //   filepath: fileName,
      //   webviewPath: imageUrl,
      // };
      // this.Photos.push(savedFileImage);
      // console.log(this.Photos);
      this.uploadedPhotos.unshift({
        path: fileCloudPath,
        url: imageUrl,
        description: '',
        uploaded: false,
        uploadProgress: 0,
      });
      // this.emitUpdatedPhotos();
      try {
        const storageRef = firebase
          .storage()
          .ref(fileCloudPath)
          .putString(imageUrl, 'data_url');
        // .put(imageUrl);

        // tracing upload progress.
        storageRef.on(
          `state_changed`,
          snapshot => {
            this.uploadedPhotos[0].uploadProgress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          },
          error => {
            console.log(error.message);
          },
          () => {
            // this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then(url => {
              let imgDownloadUrl = url;
              console.log(imgDownloadUrl);
              this.uploadedPhotos[0].url = imgDownloadUrl;
              this.uploadedPhotos[0].uploaded = true;
              this.emitUpdatedPhotos();
            });
          }
        );
      } catch (err) {
        //
        console.log(err);
      }
    },
    async removePhoto(photo) {
      let photoIndex = this.uploadedPhotos.findIndex(item => item.url === photo.url);
      if (photoIndex !== -1) {
        this.uploadedPhotos.splice(photoIndex, 1);
        await firebase
          .storage()
          .ref(photo.path)
          .delete();
        this.emitUpdatedPhotos();
      }
    },
    emitUpdatedPhotos() {
      this.$emit(
        'updatePhotos',
        this.uploadedPhotos.map(v => {
          return { url: v.url, path: v.path, uploaded: v.uploaded };
        })
      );
    },
  },
};
</script>

<style lang="scss">
.rs-add-photos__wrap {
  position: fixed;
  // right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  // min-height: 4px;
  // border-top: 4px solid #203d4a;

  background-color: #203d4a;
  color: #ffffff;
  // padding: 8px 8px 8px 24px;

  transform: translateY(100%);

  // opacity: 0.7;
  transition: all 0.3s ease-in-out;
  z-index: 4;
  &.--open {
    transform: translateY(0%);
  }

  .rs-add-photos__btn-row {
    position: absolute;
    bottom: 100%;
    left: 0px;
    width: 100%;
    height: 8px;
    background-color: #203d4a;
  }
  .rs-add-photos__btn {
    position: absolute;
    bottom: 100%;
    left: 20%;

    padding: 8px;
    cursor: pointer;
    user-select: none;
    border-radius: 16px 16px 0px 0px;
    background-color: #203d4a;

    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    i {
      font-size: 24px;
      margin: 0px 8px;
    }
  }
}

.rs-gallery__wrap {
  // height: 120px;
  width: 100%;
  padding: 8px 0px;

  display: flex;
  .rs-gallery__scroll-wrap {
    width: calc(100% - 150px);
    overflow-x: auto;
    .rs-gallery__images-wrap {
      width: max-content;
      display: flex;
      align-items: center;
    }
  }
  .rs-gallery__add-new-btn {
    height: 100px;
    min-width: 150px;
    background-color: #ffffff;
    color: #203d4a;
    cursor: pointer;
    user-select: none;
    margin: 0px 8px 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    i {
      font-size: 32px;
      margin-bottom: 12px;
    }
  }
  .rs-gallery__image-wrap {
    height: 100px;
    min-width: 80px;
    // max-width: 150px;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0px 8px;
    position: relative;
    .rs-gallery__image-overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;

      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rs-gallery__image-remove {
      color: #ffffff;
      background-color: #cd3328;
      border-radius: 0 0 0 36px;
      height: 32px;
      width: 32px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 4px 4px 12px;
      display: none;
    }
    &:hover {
      .rs-gallery__image-remove {
        display: block;
      }
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
pwa-camera-modal-instance .wrapper .content {
  height: 100vh;
  width: 100vw;
}
</style>
