<template>
  <!-- <div class="fs-fe__logo" :style="{ 'background-image': logo_url }"></div> -->
  <img :src="logo_url" class="fs-fe__logo" :style="{ width: logo_width + 'px', height: logo_height + 'px' }" alt="logo" />
</template>

<script>
export default {
  props: ['logo_url', 'logo_width', 'logo_height'],
};
</script>

<style lang="scss">
.fs-fe__logo {
  width: 300px;
  //   height: 300;
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-image: url('https://homepages.cae.wisc.edu/~ece533/images/mountain.png');
}
</style>
