<template>
  <lv-input :value="value" :placeholder="placeholder" :name="name" :label="label" :required="required" :help-text="help_block_text" :bottom-bar="bottomBar" @update:modelValue="$emit('input', $event)" />
</template>
<script>
import LvInput from 'lightvue/input';
import { localValueMixin } from 'lightvue/mixins';

export default {
  mixins: [localValueMixin],
  name: 'FormInput',
  props: ['placeholder', 'label', 'name', 'value', 'required', 'placeholder_required', 'help_block', 'help_block_text', 'bottomBar'],
  components: { LvInput },
};
</script>
