<template>
  <!-- <component
    :is="field.field_type"
    v-model="formData[field.name]"
    v-bind="field"
    :rounded="schema.settings.rounded_input"
    :floatingLabel="schema.settings.floating_label"
  ></component>-->
  <div>
    <div class="fs-field_label" v-if="!checkFieldLabel && field.label">
      {{ field.label }}
    </div>
    <component :is="field.field_type" v-bind="field" :rounded="settings.styles.rounded_input" :floatingLabel="settings.styles.floating_label" :bottom-bar="settings.styles.bottom_bar" :value="modelValue" @input="updateValue" :settings="settings" @changeSection="changeSection" @resetForm="resetForm" @submit="submit"></component>
    <div v-if="errors" class="fs-pg__field-errors">
      <div v-for="(error, errorIndex) in errors" :key="errorIndex">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import LvButton from 'lightvue/button';
import FormButton from './elements/FormButton';
// import FormToggle from './elements/FormToggle.vue';
import FormToggle from 'lightvue/input-toggle';
import FormInput from './elements/FormInput.vue';
import FormRating from './elements/FormRating.vue';
import FormDropdown from './elements/FormDropdown.vue';
import FormCheckboxGroup from './elements/FormCheckboxGroup.vue';
import FormRadioGroup from './elements/FormRadioGroup.vue';
// import FormSlider from './elements/FormSlider.vue';
import FormTextarea from './elements/FormTextarea.vue';
import FormNumber from './elements/FormNumber.vue';
import FormSeparator from './elements/FormSeparator';
import InputValue from './mixins/InputValue';
import FormRangeSlider from './elements/FormRangeSlider';
import FormTextBox from './elements/FormTextBox';
import FormLogo from './elements/FormLogo';

export default {
  name: 'PlaygroundQuestion',
  props: ['field', 'settings', 'errors'],
  mixins: [InputValue],
  data() {
    return {
      hasLabel: ['FormButton', 'FormInput', 'FormDropdown', 'FormTextarea', 'FormNumber', 'FormTextBox', 'FormToggle'],
      // fieldLabel: 'Enter your Label',
    };
  },
  components: {
    FormInput,
    FormToggle,
    LvButton,
    FormButton,
    FormRating,
    FormDropdown,
    FormCheckboxGroup,
    FormRadioGroup,
    FormTextarea,
    FormNumber,
    FormSeparator,
    FormRangeSlider,
    FormTextBox,
    FormLogo,
  },
  computed: {
    checkFieldLabel() {
      if (this.hasLabel.includes(this.field.field_type)) {
        // console.log('FIELD WITH LABELS', this.field.field_type);
        return true;
      }
    },
  },

  methods: {
    submit() {
      this.$emit('submit');
    },
    changeSection(e) {
      console.log('emitted change section at playground Question,', e);
      this.$emit('changeSection', e);
    },
    redirectForm() {
      console.log('on playground questn');
      this.$emit('redirectForm');
    },
    resetForm() {
      this.$emit('resetForm');
    },
  },
  // mounted() {
  //   if(this.field.field_type==="FormToggle"){
  //     console.log("Toggle Value",this.modelValue);
  //   }
  //   debugger;
  // },
  // computed: {
  //   schema: {
  //     get: function () {
  //       return this.$store.state.builder.schema;
  //     },
  //     set: function (newValue) {
  //       this.$store.dispatch("builder/setSchema", newValue);
  //     },
  //   },
  // },
};
</script>

<style lang="scss">
.fs-pg__field-errors {
  color: #ff0b04;
  font-size: 12px;
  margin-top: 4px;
  line-height: 16px;
}
.lv-checkbox .state label:before,
.lv-checkbox .state label:after {
  top: 0px !important;
}

.fs-field_label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
  color: #495057;
}
</style>
