<template>
  <div>
    <!-- <div class="fs-field_label" v-if="label_req">{{ label }}</div> -->
    <lv-radio-group :options="options" :pulse="pulse" color="success" outline :value="modelValue" @input="updateValue" />
  </div>
</template>

<script>
import LvRadioGroup from 'lightvue/radio-group';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    LvRadioGroup,
  },
  props: ['options', 'color', 'pulse'],
};
</script>

<style lang="scss">
.fs-field_label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
  color: #495057;
}
</style>
