<template>
  <svg viewBox="0 0 209 186" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="hidden">
    <defs>
      <clipPath id="clip0"><rect x="357" y="267" width="209" height="186" /></clipPath>
      <clipPath id="clip1"><rect x="358" y="268" width="208" height="184" /></clipPath>
      <clipPath id="clip2"><rect x="358" y="268" width="208" height="184" /></clipPath>
      <clipPath id="clip3"><rect x="358" y="268" width="565" height="184" /></clipPath>
    </defs>
    <g clip-path="url(#clip0)" transform="translate(-357 -267)">
      <g clip-path="url(#clip1)">
        <g clip-path="url(#clip2)">
          <g clip-path="url(#clip3)">
            <path
              d="M351.107 95.0243 351.107 119.278C351.107 120.647 350.857 121.146 349.348 121.126 344.572 121.126 344.572 121.126 344.572 116.35L344.572 88.7386C344.572 86.9899 345.091 86.5302 346.75 86.6501 348.793 86.8 350.843 86.8 352.886 86.6501 354.605 86.5102 355.304 87.0898 355.724 88.7786 357.413 95.5639 359.282 102.299 361.09 109.055 361.09 109.145 361.2 109.214 361.41 109.504 362.599 105.127 363.748 100.92 364.888 96.7031 365.667 93.8151 366.467 90.9371 367.146 88.0191 367.386 87.0198 367.816 86.6501 368.835 86.6701 371.393 86.6701 373.951 86.6701 376.51 86.6701 377.509 86.6701 377.999 86.8599 377.999 88.0291 377.959 98.6285 377.959 109.234 377.999 119.847 377.999 120.906 377.609 121.086 376.66 121.126 371.443 121.296 371.443 121.326 371.443 116.06L371.443 95.7438 371.183 95.6838 366.836 112.532C366.217 114.941 365.557 117.329 365.028 119.757 364.808 120.756 364.438 121.086 363.399 121.116 358.042 121.256 358.032 121.296 356.693 116.12 355.064 109.864 353.466 103.588 351.847 97.3227 351.637 96.7131 351.417 96.0936 351.107 95.0243Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path
              d="M418.171 96.853C416.932 97.2328 416.342 96.5732 415.882 95.1442 415.013 92.4761 411.885 91.2669 408.268 91.9964 407.519 92.1606 406.789 92.4017 406.089 92.7159 405.019 93.0704 404.292 94.0664 404.28 95.1942 404.276 96.314 404.964 97.3201 406.009 97.7224 408.225 98.7218 410.544 99.4725 412.924 99.9609 414.793 100.402 416.625 100.99 418.401 101.72 422.226 103.239 424.619 107.069 424.307 111.173 424.167 115.5 422.098 118.828 417.971 120.387 413.265 122.226 408.034 122.194 403.351 120.297 399.094 118.608 397.355 114.98 396.476 110.803 396.296 109.944 396.816 109.804 397.475 109.804 399.124 109.624 400.783 109.464 402.472 109.274 403.151 109.194 403.301 109.554 403.471 110.144 404.7 115.79 409.637 116.529 414.004 115.34 416.02 114.88 417.46 113.101 417.491 111.033 417.371 108.855 415.802 108.035 414.004 107.466 410.946 106.556 407.838 105.777 404.8 104.818 402.118 104.03 399.884 102.162 398.634 99.6611 396.746 95.7656 397.941 91.0762 401.462 88.5588 406.099 85.251 415.123 85.361 419.66 88.7586 421.686 90.3667 423.017 92.6922 423.377 95.2541 423.537 96.0636 423.377 96.5932 422.378 96.6032 420.979 96.6232 419.71 96.7831 418.171 96.853Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path d="M486.853 99.5212C486.793 103.668 487.093 107.845 486.564 111.963 485.854 117.399 482.996 120.367 477.67 121.316 474.448 121.906 471.14 121.835 467.946 121.106 463.459 120.21 460.134 116.41 459.842 111.843 459.033 103.848 459.642 95.8537 459.442 87.8592 459.442 86.9599 459.702 86.5202 460.632 86.6301L460.831 86.6301C466.378 86.3803 466.368 86.3803 466.378 91.9364 466.387 97.4926 466.378 103.059 466.378 108.615 466.378 115.12 471.104 116.18 475.221 115.54 478.219 115.07 479.728 113.152 479.768 109.814 479.858 102.979 479.768 96.1435 479.848 89.3083 479.848 86.3103 479.399 86.6501 482.446 86.6301 486.823 86.6301 486.823 86.6301 486.813 91.037 486.843 93.8551 486.853 96.7031 486.853 99.5212Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path
              d="M243.372 103.848C243.372 98.7417 243.422 93.6253 243.372 88.5188 243.372 87.1597 243.612 86.6301 245.14 86.6501 251.856 86.74 258.571 86.72 265.297 86.6501 266.666 86.6501 267.005 87.0998 267.005 88.4089 267.005 92.6359 267.005 92.6359 262.828 92.6359 259.241 92.6359 255.653 92.7159 252.076 92.6359 250.587 92.6359 250.197 93.0756 250.297 94.4747 250.397 96.0687 250.397 97.6674 250.297 99.2614 250.227 100.421 250.587 100.81 251.796 100.78 255.563 100.71 259.341 100.78 263.118 100.78 264.287 100.78 264.817 100.97 264.827 102.319 264.827 106.576 264.917 106.576 260.69 106.576 257.812 106.576 254.924 106.676 252.056 106.576 250.547 106.506 250.257 107.036 250.287 108.415 250.377 112.172 250.287 115.95 250.347 119.717 250.347 120.896 250.027 121.326 248.838 121.206 247.079 121.016 244.841 121.956 243.701 120.796 242.852 119.907 243.412 117.659 243.402 116.01 243.362 111.843 243.372 107.845 243.372 103.848Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path d="M444.842 106.846 444.842 119.497C444.842 120.687 444.613 121.246 443.283 121.136 441.575 120.986 439.346 121.646 438.287 120.786 437.228 119.927 437.937 117.559 437.927 115.86 437.927 108.865 437.837 101.8 437.927 94.7745 437.927 92.9257 437.348 92.526 435.679 92.626 433.44 92.7559 431.192 92.626 428.953 92.626 427.834 92.626 427.584 92.2462 427.554 91.1969 427.444 86.6501 427.414 86.6501 432.021 86.6501L453.157 86.6501C454.586 86.6501 455.155 86.9199 455.155 88.5288 455.075 92.6359 455.155 92.6359 451.058 92.6359 449.651 92.7259 448.239 92.7259 446.831 92.6359 445.032 92.4061 444.732 93.1756 444.772 94.7745 444.912 98.7717 444.842 102.849 444.842 106.846Z" fill="#38B3AC" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path d="M535.68 103.848 535.68 119.347C535.68 120.587 535.49 121.266 534.041 121.116 532.342 120.946 530.123 121.806 529.044 120.786 527.965 119.767 528.694 117.529 528.694 115.79 528.694 106.666 528.694 97.5425 528.634 88.4189 528.634 86.9799 529.014 86.6001 530.453 86.6101 535.65 86.6101 535.65 86.6101 535.65 91.7466Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path
              d="M329.412 105.927C331.781 105.317 333.749 104.768 335.278 103.319 338.366 100.391 338.846 96.7931 337.627 92.9257 336.317 88.7586 333.06 87.0698 328.963 86.9299 323.137 86.74 317.301 86.78 311.485 86.6101 310.006 86.6101 310.006 87.2896 310.006 88.3589L310.006 115.78C310.006 117.509 309.286 119.777 310.365 120.776 311.445 121.776 313.653 120.946 315.362 121.096 316.621 121.206 316.991 120.756 316.991 119.487 316.921 115.85 316.991 112.202 316.921 108.565 316.921 107.066 317.421 106.756 318.8 106.756 321.415 106.568 323.913 107.871 325.255 110.124 327.434 113.442 329.702 116.689 331.92 119.977 332.24 120.679 332.948 121.124 333.719 121.106 336.018 121.106 338.326 121.106 341.114 121.106 337.217 115.74 334.988 109.724 329.412 105.927ZM327.234 101.12C324.236 101.31 321.108 101.22 318.05 101.27 317.211 101.27 316.931 100.91 316.941 100.121L316.941 93.8251C316.941 93.0057 317.261 92.6759 318.08 92.6959L322.667 92.6959C324.136 92.6959 325.615 92.616 327.074 92.6959 329.281 92.7233 331.049 94.535 331.021 96.7425 331.021 96.7427 331.021 96.7429 331.021 96.7431L331.021 96.873C331.163 99.076 329.493 100.977 327.29 101.12 327.288 101.12 327.286 101.12 327.284 101.12Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path
              d="M522.269 97.2128C520.5 90.4674 516.213 87.0998 509.278 86.8699 504.921 86.73 500.564 86.8 496.217 86.6501 494.658 86.6501 494.138 86.9998 494.138 88.6487 494.208 93.7552 494.138 98.8716 494.138 103.988 494.138 109.105 494.138 114.341 494.138 119.487 494.138 120.557 494.278 121.086 495.567 121.086 500.024 121.026 504.561 121.156 509.018 121.026 515.743 120.826 520.38 117.339 522.119 111.203 523.417 106.637 523.469 101.806 522.269 97.2128ZM515.274 109.804C514.364 113.442 512.406 115.05 508.618 115.26 506.62 115.37 504.621 115.26 502.622 115.34 501.513 115.34 501.094 114.961 501.233 113.901L501.233 94.1449C501.233 92.9757 501.583 92.546 502.762 92.606 504.491 92.6959 506.22 92.606 507.949 92.6859 512.136 92.8358 514.664 94.8844 515.484 98.9716 516.224 102.553 516.152 106.254 515.274 109.804Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path d="M574.033 100.281C573.413 93.7744 568.768 88.3613 562.431 86.76 555.186 84.8613 548.301 86.9899 544.194 92.4661 541.356 96.2035 540.856 100.64 540.946 105.197 541.146 115.19 547.561 121.596 557.554 121.596 566.388 121.596 572.214 116.979 573.873 108.485 574.341 105.774 574.395 103.008 574.033 100.281ZM565.039 111.733C563.181 114.841 560.163 115.95 556.675 115.66 553.396 115.483 550.513 113.433 549.27 110.394 548.424 108.424 548.028 106.29 548.111 104.148 548.111 100.341 548.561 96.943 551.369 94.4147 555.053 91.0014 560.807 91.221 564.22 94.9052 565.032 95.7822 565.664 96.8109 566.079 97.9323 567.777 102.589 567.707 107.336 565.079 111.733Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path d="M304.05 98.6318C302.651 92.3362 298.983 88.0891 292.458 86.72 285.932 85.351 279.827 86.1005 275.18 91.7166 272.182 95.3141 271.262 99.5212 271.342 104.148 271.242 108.805 272.282 113.142 275.599 116.799 279.507 121.056 284.533 121.926 289.989 121.586 296.389 121.292 301.894 116.963 303.69 110.813 304.861 106.852 304.985 102.655 304.05 98.6318ZM296.795 108.765C295.591 113.658 290.648 116.648 285.755 115.444 282.517 114.647 279.972 112.147 279.117 108.925 278.609 107.107 278.366 105.226 278.397 103.339 278.477 102.599 278.557 101.19 278.817 99.811 279.47 95.2952 283.4 91.9834 287.961 92.1063 292.568 92.1063 295.875 94.7945 296.955 99.4712 297.667 102.535 297.612 105.727 296.795 108.765Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path d="M86.1704 97.2228 95.5739 97.2228C98.7117 97.2228 101.89 97.2228 104.987 97.2228 107.086 97.3739 109.077 96.2729 110.064 94.4147 113.302 88.7653 116.566 83.1358 119.857 77.5264 120.836 76.0492 120.867 74.1381 119.937 72.6298 116.593 66.8471 113.222 61.0511 109.824 55.2418 108.967 53.6893 107.309 52.7506 105.537 52.8135L85.7607 52.8135C83.9899 52.7463 82.332 53.6815 81.4737 55.2318 78.1426 60.9878 74.8116 66.7272 71.4806 72.4499 70.5004 74.0037 70.5004 75.9826 71.4806 77.5364 74.7383 83.1791 77.9994 88.8152 81.2638 94.4447 82.2223 96.2438 84.1346 97.3265 86.1704 97.2228Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path d="M111.173 115.45C110.27 113.7 108.434 112.632 106.466 112.712L87.2497 112.712C85.4089 112.615 83.67 113.561 82.7528 115.16 79.4218 120.916 76.0907 126.666 72.7597 132.408 71.8289 133.914 71.8523 135.822 72.8196 137.305 76.0574 142.881 79.3052 148.467 82.5129 154.063 83.3957 155.82 85.226 156.895 87.1897 156.812L106.436 156.812C108.473 156.94 110.389 155.838 111.303 154.014 114.401 148.577 117.499 143.131 120.707 137.755 121.925 135.975 121.925 133.629 120.707 131.849 117.479 126.443 114.321 120.916 111.173 115.45Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
            <path
              d="M214.562 93.2355C200.711 69.3321 186.847 45.4352 172.97 21.5451 169.143 14.9696 163.357 11.6719 155.712 11.6719L72.3 11.6719C64.5853 11.6719 58.7694 14.9996 54.902 21.665 44.1795 40.1522 33.3769 58.5595 22.8142 77.1267 18.4073 84.8613 12.9111 92.0464 10.3428 100.75L10.2729 106.476C11.0141 109.045 12.0187 111.53 13.2708 113.891 27.2312 137.905 41.0816 161.888 55.0719 185.871 58.4011 192.007 64.9134 195.734 71.8903 195.495L155.892 195.495C162.946 195.761 169.537 191.989 172.88 185.772 186.758 161.788 200.655 137.838 214.572 113.921 218.559 107.016 218.559 100.171 214.562 93.2355ZM190.119 110.713C184.872 119.807 179.626 128.911 174.379 137.905 169.133 146.898 163.887 155.812 158.76 164.806 155.682 170.192 151.155 172.87 144.95 172.87L83.0626 172.87C76.8569 172.87 72.2201 170.352 69.0722 164.996 59.4989 148.454 49.9221 131.915 40.3421 115.38L37.8538 111.063C34.9594 106.496 34.9206 100.679 37.7539 96.0736 48.26 77.9328 58.786 59.8053 69.3321 41.6912 71.9533 36.8228 77.129 33.8835 82.6528 34.1264 93.0923 34.1264 103.552 34.1264 114.031 34.1264L145.17 34.1264C150.693 33.8733 155.874 36.8054 158.5 41.6712 169.113 59.8986 179.679 78.1526 190.198 96.4333 192.862 100.829 192.831 106.348 190.119 110.713Z"
              fill="#38B3AB"
              transform="matrix(1.00156 0 0 1 347.711 256.397)"
            />
            <path d="M162.807 85.251C161.952 83.6223 160.239 82.6278 158.4 82.6928L138.604 82.6928C136.768 82.6361 135.06 83.6335 134.207 85.261 130.999 90.8572 127.782 96.4533 124.494 102.009 123.36 103.695 123.36 105.9 124.494 107.586 127.772 113.152 130.989 118.738 134.207 124.334 135.066 126.036 136.851 127.067 138.754 126.962L148.547 126.962C151.855 126.962 155.083 126.962 158.351 126.962 160.257 127.06 162.044 126.032 162.917 124.334 166.185 118.698 169.433 113.042 172.711 107.406 173.691 105.852 173.691 103.873 172.711 102.319 169.423 96.6232 166.105 90.9371 162.807 85.251Z" fill="#38B3AB" transform="matrix(1.00156 0 0 1 347.711 256.397)" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>
