<template>
  <RangeSlider :min="min" :max="max" :label="label" :step="step" :sliderColor="sliderColor" :trackColor="trackColor" :value="modelValue" @input="updateValue" />
</template>

<script>
import RangeSlider from 'lightvue/rangeslider/RangeSlider';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    RangeSlider,
  },

  props: ['min', 'max', 'step', 'sliderColor', 'trackColor', 'label'],
};
</script>

<style></style>
