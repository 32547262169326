<template>
  <div>
    <!----------------------------------------------PLAYGROUND------------------------------------------------------------>

    <section class="fs-pg" v-if="schema && schema.settings" :style="{ background: schema.settings.styles.playground_background }">
      <div
        class="fs-pg__form-wrap"
        :style="{
          'border-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color,
          'border-radius': schema.settings.styles.border_radius,
          background: schema.settings.styles.form_background,
        }"
        :class="{ '--no-border': !schema.settings.border_width, '--form-wrap-shadow': !schema.settings.styles.form_shadow }"
      >
        <div class="fs-pg__form-header" :style="{ 'background-color': schema.settings.styles.form_layout_color || schema.settings.styles.form_theme_color, color: schema.settings.styles.header_font_color }" :class="{ '--d-none': !schema.settings.form_header, '--ai-center': schema.settings.form_header_align == 'center', '--ai-end': schema.settings.form_header_align == 'right' }">
          <div class="fs-pg__form-heading" :style="{ 'font-size': schema.settings.styles.header_font_size + 'px' }">
            {{ schema.settings.form_heading }}
          </div>
          <div class="fs-pg__form-desc" :style="{ 'font-size': schema.settings.styles.header_descp_font_size + 'px' }">
            {{ schema.settings.form_description }}
          </div>
        </div>
        <ProgressBar v-if="schema && schema.sections" :color="schema.settings.styles.progressbar_color || schema.settings.styles.form_theme_color" :section-count="schema.sections.length" :selected-section="selectedSection + 1" class="fs-pg__progressbar" :class="{ '--d-none': schema.settings.styles.progressbar_hide }" />
        <form-separator class="fs-pg__separator" :class="{ '--d-none': !schema.settings.styles.header_separator_visibility }" />

        <div class="fs-pg__form-body" v-if="schema && schema.sections">
          <div v-for="(section, sectionIndex) in schema.sections" :key="'sec_' + section.id">
            <div v-show="sectionIndex === selectedSection">
              <!----- Section heading -->
              <div class="fs-pg__form-section-heading" v-if="schema.settings && schema.settings.section_heading_visibility && schema.sections[sectionIndex].heading !== undefined && schema.sections[sectionIndex].rows" :style="{ 'background-color': schema.settings.styles.sectionHeader_color, 'border-left-color': schema.settings.styles.sectionHeaderFont_color }">
                {{ schema.sections[sectionIndex].heading }}
              </div>
              <!-- -------- -->
              <div v-if="schema && schema.sections[sectionIndex] && schema.sections[sectionIndex].rows">
                <div v-for="(row, rowIndex) in schema.sections[sectionIndex].rows" :key="'row_' + row.id">
                  <div class="fs-pg__form-row" v-for="(field, fieldIndex) in row.fields" :key="field.id">
                    <!-- <component
                  :is="field.field_type"
                  v-model="formData[field.name]"
                  v-bind="field"
                  :rounded="schema.settings.rounded_input"
                  :floatingLabel="schema.settings.floating_label"
                ></component>-->

                    <PlaygroundQuestion :field="field" :settings="schema.settings" v-model="formData[field.id]" @changeSection="changeSelectedSection" @resetForm="resetForm" @submit="onSubmit" :errors="formErrors[field.id]" />
                  </div>
                </div>
              </div>

              <!--- Next & Back Section Button-- -->
              <div class="fs-pg__next-back-row" v-if="schema && schema.sections[sectionIndex] && schema.sections[selectedSection].rows.length && schema.sections[selectedSection].config.default_btn !== false">
                <lv-button push class="lv--success" v-if="sectionIndex > 0 && selectedSection + 1 !== schema.sections.length" @click="changeSelectedSection(selectedSection - 1)" icon="light-icon-arrow-left" label="Back" :rounded="schema.settings.styles.rounded_input" :style="{ 'background-color': schema.settings.styles.form_theme_color }" />
                <div v-if="sectionIndex === 0"></div>
                <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" v-if="sectionIndex + 1 <= schema.sections.length - 2" @click="onSubmit({ partial: true })" icon-right="light-icon-arrow-right" :style="{ 'background-color': schema.settings.styles.form_theme_color }">Next</lv-button>
                <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" icon-right="light-icon-plane-departure" @click="onSubmit" v-if="sectionIndex + 1 === schema.sections.length - 1 && !submitting" :style="{ 'background-color': schema.settings.styles.form_theme_color }">Submit</lv-button>
                <lv-button push class="lv--success" :rounded="schema.settings.styles.rounded_input" @click="onSubmit" v-if="sectionIndex + 1 === schema.sections.length - 1 && submitting" :style="{ 'background-color': schema.settings.styles.form_theme_color, pointerEvents: 'none', opacity: '0.5' }">Submiting</lv-button>
              </div>
            </div>
          </div>
          <!-- Show only if not availabe in schema -->
        </div>
        <!-- <ThankYouCard v-else/> -->
        <!-- Thank you Section -->
        <!--  -->
        <!-- **************************************** -->
      </div>
      <!-- ************ -->
      <div class="watermark-wrap">
        <watermark />
      </div>
    </section>
    <!-- <AdCard v-if="submitted === true" /> -->

    <!-- ********************************************************************************************************* -->
  </div>
</template>

<script>
import PlaygroundQuestion from './PlaygroundQuestion.vue';
import Watermark from './Watermark.vue';
import LvButton from 'lightvue/button';
import ProgressBar from './ProgressBar';
// import ThankYouCard from './ThankYouCard';
// import AdCard from './AdCard';
import FormSeparator from './elements/FormSeparator';
export default {
  name: 'FormPreview',
  props: ['schema', 'prefill', 'prefilledSteps'],

  components: {
    Watermark,
    LvButton,
    PlaygroundQuestion,
    ProgressBar,
    // ThankYouCard,
    // AdCard,
    FormSeparator,
  },

  data() {
    return {
      selectedSection: 0,
      formData: {},
      formErrors: {},
      submitted: false,
      submitting: false,
    };
  },
  watch: {
    prefill: {
      handler: function(newValue) {
        this.formData = JSON.parse(JSON.stringify(newValue)); // mandatory to prvent infinite loop.
        if (this.prefilledSteps) {
          this.changeSelectedSection(this.prefilledSteps);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeSelectedSection(newSectionIndex, others) {
      this.selectedSection = newSectionIndex;
      if (others && others.reset) {
        this.$router.push({ query: { response: '', step: parseInt(newSectionIndex) + 1 } });
      } else {
        this.$router.push({ query: { ...this.$route.query, step: parseInt(newSectionIndex) + 1 } });
      }
      // console.log(this.formPhotos);
    },
    resetForm() {
      this.changeSelectedSection(0, { reset: true });
      this.submitted = false;
      this.formData = {};
      this.$emit('submitAnother');
      // this.$router.push({ query: { ...this.$route.query, step: 0 + 1 } });
    },
    onSubmit(payload) {
      //---------***** Validations ***
      this.formErrors = {};
      // this.schema.sections.forEach(section => {
      this.schema.sections[this.selectedSection].rows.forEach(row => {
        let fieldErrors = [];
        // row.fields.forEach((field, fieldIndex) => {
        if (row.fields[0].required === true) {
          if (this.formData[row.fields[0].id] === undefined || this.formData[row.fields[0].id] === '') {
            // i.e. not filled
            fieldErrors.push('This field is required');
            this.formErrors[row.fields[0].id] = fieldErrors;
          }
        }
        // });
        // console.log(' row field value', this.formData[row.fields[0].id]);
        // });
      });
      // Object.keys(this.formErrors).forEach(function(key) {
      //   if (this.formErrors[key]) {
      //     console.log(key);
      //   }
      // });

      if (Object.values(this.formErrors).length > 0) {
        console.log('value of object', Object.values(this.formErrors).length);

        return;
      }
      // -----------------------------------
      this.$emit('submit', {
        form_data: this.formData,
        is_partial: payload && payload.partial === true ? true : false,
        current_section_index: this.selectedSection,
      });
      this.submitting = true;

      let waitDuration = payload && payload.partial === true ? 0 : 2000;

      setTimeout(() => {
        if (this.selectedSection + 1 < this.schema.sections.length) {
          this.changeSelectedSection(this.selectedSection + 1); // might break anytime
        }
        if (payload && payload.partial === true) {
          //
        } else {
          setTimeout(() => {
            this.submitted = true; // no more usefull
          }, 400);
        }
        this.submitting = false;
      }, waitDuration);
    },
    redirectForm() {
      let redirect_url = 'https://' + this.schema.settings.redirect_url;
      window.open(redirect_url, '_blank');
    },
  },
};
</script>

<style lang="scss">
/* Thank you only */
/* Intro To responser card */
@import './Playground.scss';

.watermark-wrap {
  max-width: 700px;
  margin: 20px auto;
  display: flex;
  justify-content: flex-end;
}

/* ------ */
</style>
