<template>
  <div class="btn-row" :class="{ 'btn-align-left': align === 'left', 'btn-align-center': align === 'center', 'btn-align-right': align === 'right' }" :style="{ 'margin-top': btn_margin_top + 'px' }">
    <Button :label="label" :raised="raised" :deepShadow="deepShadow" :rounded="rounded" :deepShadowHover="deepShadowHover" :push="push" :outlined="outlined" class="lv--success" :style="{ 'background-color': background_color, color: font_color, width: btn_width + 'px' }" @click="callToAction" :value="modelValue" @input="updateValue" />
  </div>
</template>

<script>
import Button from 'lightvue/button';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    Button,
  },

  props: ['label', 'raised', 'deepShadow', 'rounded', 'deepShadowHover', 'push', 'outlined', 'align', 'background_color', 'font_color', 'btn_width', 'btn_margin_top', 'sec_index', 'sec_count', 'selected_section', 'settings', 'button_type'],
  // here sec_index is starting from 1 ,receiving as prop for CTA from element Properties
  methods: {
    callToAction() {
      if (this.button_type === 'submit' && this.sec_index == this.sec_count) {
        console.log('emit submit in formbutton');
        this.$emit('submit');
        // this.button_type = 'normal';
      }
      if (this.button_type === 'resubmit') {
        this.$emit('resetForm');
      }
      if (this.selected_section < this.sec_count) {
        this.emitChangeSection();
      }

      // if (this.selected_section == this.sec_count) {
      //   this.redirectForm();
      // }
    },
    // callToAction2() {
    //   if (this.settings.multiple_responses) {
    //     this.$emit('resetForm');
    //   }
    // },
    emitChangeSection() {
      console.log('Emitted change sec in formbutton', this.sec_index);
      this.$emit('changeSection', this.sec_index - 1); //since changeSection index starts from 0
    },
    redirectForm() {
      // console.log('sec_index', this.selected_section);
      // console.log('sec_count', this.sec_count);
      let redirect_url = 'https://' + this.settings.redirect_url;
      window.open(redirect_url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-row {
  display: flex;
  &.btn-align-center {
    justify-content: center;
    align-items: center;
  }
  &.btn-align-left {
    justify-content: left;
    align-items: flex-start;
  }
  &.btn-align-right {
    justify-content: flex-end;
  }
  &.--d-none {
    display: none;
  }
}

.multiple_res_btn {
  &.--low-opacity {
    opacity: 0.5;
  }
  &.--d-none {
    display: none;
  }
}
</style>
