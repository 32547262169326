<template>
  <!-- <div :value="modelValue" @input="updateValue"> -->
  <Dropdown :options="options" :label="label" :rounded="rounded" :placeholder="placeholder" :value="modelValue" @input="updateValue" :bottom-bar="bottomBar" clearable> </Dropdown>
  <!-- </div> -->
</template>

<script>
import { trueValueMixin } from 'lightvue/mixins';

import Dropdown from 'lightvue/dropdown/Dropdown.vue';
export default {
  mixins: [trueValueMixin],

  components: {
    Dropdown,
  },
  props: ['placeholder', 'options', 'rounded', 'label', 'bottomBar'],
};
</script>

<style></style>
