<template>
  <!-- <div> -->
  <!-- <div class="fs-field_label" v-if="label_req">{{ label }}</div> -->
  <lv-checkbox-group :options="options" color="success" :pulse="pulse" outline :value="modelValue" @input="updateValue"></lv-checkbox-group>
  <!-- </div> -->
</template>

<script>
import LvCheckboxGroup from 'lightvue/checkbox-group';
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  components: {
    LvCheckboxGroup,
  },

  props: ['options', 'color', 'pulse'],

  data() {
    return {
      //   chosenCities1: [{ name: 'Paris', code: 'PRS' }],
      //   chosenCities2: ['LDN', 'NY'],
      //   chosenCities3: ['USA'],
      //   chosenCities4: null,
    };
  },
};
</script>

<style lang="scss">
.fs-field_label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.8;
  color: #495057;
}
</style>
