<template>
  <div class="hr-shadow" :value="modelValue" @input="updateValue"></div>
</template>

<script>
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  name: 'FormSeparator',
};
</script>

<style>
.hr-shadow {
  background-color: #dee2e6;
  padding: 0;
  width: 100%;
  /* width: calc(100% + 60px); */
  /* margin-left: -30px; */
  height: 4px;
  border: 0;
  /* box-shadow: 0 10px 10px -10px  inset !important;  */
}
/* hr.hr-shadow { */
/* height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8c8c inset !important; */
/* } */
</style>
