<template>
  <a href="https://formstudio.io/" target="_blank" class="fs-pg__wmark-wrap">
    <logo-svg class="fs-pg__wmark-logo" />
    <div class="watermark-head">Powered by <span style="font-weight: 600">FORM STUDIO</span></div>
  </a>
</template>

<script>
import LogoSvg from './common/LogoSvg.vue';
export default {
  components: {
    LogoSvg,
  },
};
</script>

<style lang="scss">
.fs-pg__wmark-wrap {
  display: flex;
  background-color: #dcf3f1;
  font-size: 14px;
  color: #203d4a;
  border-radius: 4px;
  padding: 6px 10px;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
  text-decoration: none;

  user-select: none;
  cursor: pointer;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  .fs-pg__wmark-logo {
    width: 20px;
    margin-right: 8px;
  }
}
</style>
