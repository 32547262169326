<template>
  <div>
    <div class="fs-pg__thankCard__body">
      <br /><br />
      <div class="thankyou-msg-div">
        <i class="light-icon-check"></i>
        <span class="thank-msg">{{ msg }}</span>
      </div>
      <br />
      <span class="thank-sub-msg">{{ sub_msg }}</span>
    </div>
  </div>
</template>

<script>
import { trueValueMixin } from 'lightvue/mixins';

export default {
  mixins: [trueValueMixin],

  props: ['msg', 'sub_msg'],
};
</script>

<style></style>
