<template>
  <div>
    <preview-module :schema="formData.form_data.schema" :prefill="prefilledResponse" :prefilledSteps="prefilledSteps" @submit="onSubmit" @submitAnother="submitAnother" />
    <add-photos :response-id="responseId" :responseMeta="responseMeta" :photos="photos" @updatePhotos="photos = $event" />
    <!-- <bottom-bar /> -->
  </div>
</template>

<script>
import PreviewModule from '../components/PreviewModule.vue';
import AddPhotos from '../components/AddPhotos.vue';
import BottomBar from '../components/BottomBar.vue';
import { uid } from '@main/utils/index.js';
// import { defaultFormData } from '../store/default.js';
import { Plugins } from '@capacitor/core';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
export default {
  components: {
    PreviewModule,
    BottomBar,
    AddPhotos,
  },
  data() {
    return {
      responses: [],
      formData: this.$store.state.FormData,
      responseId: 'res_' + uid(),
      locationCoordinates: '',
      user: null,
      photos: [],
      responseMeta: {
        district: '',
        cluster: '',
        panchayat: '',
        habitation: '',
      },
      prefilledResponse: {},
      prefilledSteps: 0,
    };
  },
  created() {
    this.prefillForm();
  },
  mounted() {
    this.traceLocation();
  },

  methods: {
    prefillForm() {
      if (this.$route && this.$route.query && this.$route.query.response) {
        let responsesRaw = localStorage.getItem('responses');
        let responsesArr = responsesRaw ? JSON.parse(responsesRaw) : [];
        let currentResponseIndex = responsesArr.findIndex(res => res.response_id === this.$route.query.response);
        if (currentResponseIndex !== -1) {
          let currentResponseObject = responsesArr[currentResponseIndex];
          this.responseMeta = currentResponseObject.other_data.response_meta;
          this.photos = currentResponseObject.other_data.photos;
          // this.prefilledSteps = currentResponseObject.status === 'pending' ? currentResponseObject.other_data.steps_completed - 1 : currentResponseObject.other_data.steps_completed;
          this.prefilledSteps = currentResponseObject.other_data.steps_completed - 1;
          this.responseId = currentResponseObject.response_id;
          this.prefilledResponse = currentResponseObject.response_data;
        }
      }
    },
    async traceLocation() {
      const { Geolocation } = Plugins;
      let curPossition = await Geolocation.getCurrentPosition();
      this.locationCoordinates = {
        lat: curPossition.coords.latitude,
        lng: curPossition.coords.longitude,
      };
      console.log('geo cordinates', this.locationCoordinates);
    },
    async onSubmit(payload) {
      const { form_data: submitFormValue, is_partial: isPartial, current_section_index } = payload;
      if (current_section_index === 0) {
        this.responseMeta = {
          district: submitFormValue['KImbz8tVIjbEnZ2uaEPR'] || '',
          cluster: submitFormValue['nqDl2ueKwAHeRYHbfnLE'] || '',
          panchayat: submitFormValue['UP00hal8I0jMOUYZLhtJ'] || '',
          habitation: submitFormValue['iSvs8RxdUK4fTvI1hs0U'] || '',
        };
      }

      // STEP 0: Collect basic informations
      let submitStatus = isPartial ? 'partial' : 'pending';
      this.user = JSON.parse(localStorage.getItem('user'));

      // STEP 1: Form the response object.
      let responseObject = {
        form_id: this.formData.form_id,
        response_id: this.responseId,
        response_data: submitFormValue,
        submission_status: isPartial ? 'partial' : 'complete',
        other_data: {
          geo_coordinates: { ...this.locationCoordinates },
          user: this.user,
          photos: this.photos,
          steps_completed: current_section_index + 1,
          response_meta: this.responseMeta,
        },
      };

      // STEP 2: Store in localstorage.
      let allLocalResponsesRaw = localStorage.getItem('responses');
      let allResponses = [];
      if (allLocalResponsesRaw) {
        allResponses = JSON.parse(allLocalResponsesRaw);
      }
      let localResponseObject = {
        ...responseObject,
        status: submitStatus,
      };
      let currentResponseIndex = allResponses.findIndex(res => res.response_id === this.responseId);
      if (currentResponseIndex !== -1) {
        allResponses[currentResponseIndex] = localResponseObject;
      } else {
        allResponses.push(localResponseObject);
      }
      localStorage.setItem('responses', JSON.stringify(allResponses));
      console.log('Saved in LocalStorage !!');

      // STEP 3: Store in RURBAN Firestore.
      try {
        var db = firebase.firestore();
        const responseDocRef = db.collection('responses').doc(this.responseId);
        const doc = await responseDocRef.set(responseObject, {
          merge: true,
        });
        console.log('Saved in RURBAN !!');
      } catch (error) {
        console.log(error.message);
        console.warn('FAILED to Saved in RURBAN !!');
      }

      // STEP 4: HIT endpoint to save in MongoDB
      try {
        const baseURL =
          // 'http://link.formstudio.io';
          'https://api.rurban.formstudio.io';
        // 'http://localhost:3000';
        const { data } = await this.$axios.post(`${baseURL}/api/submit`, { ...responseObject, form_data: this.formData.form_data });
        submitStatus = 'success';
        console.log('Saved in SERVER !!');
      } catch (error) {
        submitStatus = 'failed';
        console.log(error.message);
        console.warn('FAILED to Saved in SERVER !!');
      } finally {
        // store status in local_storage
        if (!isPartial && currentResponseIndex !== -1) {
          allResponses[currentResponseIndex] = { ...localResponseObject, status: submitStatus };
          localStorage.setItem('responses', JSON.stringify(allResponses));
          console.log('Saved in LocalStorage again !!');
        }
      }
    },
    submitAnother() {
      this.responseId = 'res_' + uid();
      this.photos = [];
      this.responseMeta = {
        district: '',
        cluster: '',
        panchayat: '',
        habitation: '',
      };
    },
  },
};
</script>

<style lang="scss">
.rs-sf__back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  margin: 16px;
}
</style>
