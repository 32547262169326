<template>
  <LvProgressBar :value="increment" :showValue="false" :color="color" style="height:4px; border-radius: 0px" />
</template>

<script>
import LvProgressBar from 'lightvue/progressbar/ProgressBar.vue';

export default {
  components: {
    LvProgressBar,
  },

  props: ['sectionCount', 'selectedSection', 'color'],

  computed: {
    increment() {
      this.incrementPercent = 100 / this.sectionCount;
      return this.incrementPercent * this.selectedSection;
    },
  },
};
</script>
